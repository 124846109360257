<div class="container-fluid" style="padding-top: 10px">
  <h1><b>Gallery</b></h1>
  <div class="row">
    <div
      class="col-md-10"
      style="padding: 10px; display: flex; align-items: flex"
    >
      <mat-chip-list aria-label="Tab Selection">
        <button
          (click)="changeTab('My Posts')"
          [style.color]="selectedTab === 'My Posts' ? '#fff' : '#333333'"
          class="mx-2 mb-1 mat-stroked-button"
          style="border-radius: 30px; height: 40px; width: 100px"
          [selected]="selectedTab === 'My Posts'"
          [style.background]="selectedTab === 'My Posts' ? '#333333' : ''"
        >
          My Posts
        </button>
        <button
          (click)="changeTab('Tagged Posts')"
          [style.color]="selectedTab === 'Tagged Posts' ? '#fff' : '#333333'"
          class="mx-2 mb-1 mat-stroked-button"
          style="border-radius: 30px; height: 40px; width: 140px"
          [selected]="selectedTab === 'Tagged Posts'"
          [style.background]="selectedTab === 'Tagged Posts' ? '#333333' : ''"
        >
          Tagged Posts
        </button>
        <button
          (click)="changeTab('Tag Requests')"
          [style.color]="selectedTab === 'Tag Requests' ? '#fff' : '#333333'"
          class="mx-2 mb-1 mat-stroked-button"
          style="border-radius: 30px; height: 40px; width: 140px"
          [selected]="selectedTab === 'Tag Requests'"
          [style.background]="selectedTab === 'Tag Requests' ? '#333333' : ''"
        >
          Tag Requests
        </button>
      </mat-chip-list>
    </div>
    <div class="col-md-2">
      <button mat-raised-button class="add-default-btn" (click)="addNewImage()">
        + Add New Image/Video
      </button>
    </div>
  </div>
  <div class="row" *ngIf="galleryColumns && selectedTab === 'My Posts'">
    <ng-container *ngFor="let column of galleryColumns">
      <div class="col-lg-3 mt-3">
        <mat-card class="card-style" *ngFor="let gall of column; let i = index">
          <button mat-icon-button [matMenuTriggerFor]="menu" class="menuicon">
            <mat-icon>more_vert</mat-icon>
          </button>
          <mat-menu #menu="matMenu">
            <button mat-menu-item (click)="deleteImg(gall.id)">
              <span>Delete</span>
            </button>
          </mat-menu>
          <img
            src="{{ gall.galleryImageUrl }}"
            style="width: 100%; margin-top: 0px"
            *ngIf="gall.mediaType == 'imageUpload'"
          />
          <video  style="width: 100%; margin-top: 0px" controls *ngIf="gall.mediaType == 'videoUpload'">
            <source src="{{gall.galleryImageUrl}}" type="video/mp4">
          </video>
          <div class="row my-2" style="padding-top: 10px">
            <div class="col-7">
              <span
                class="material-icons-outlined"
                style="padding-left: 10px; cursor: pointer"
                (click)="editImage(gall)"
                >edit</span
              >
            </div>
            <div class="col-5">
              <p style="display: flex; align-items: center">
                <span style="font-weight: 500; font-size: larger; cursor: pointer;"
                (click)="taggedMembers(gall.id)">&#64;{{ gall.taggedMembers?.length }}</span
                >
                <span
                  *ngIf="gall.likesCount >= 1"
                  class="material-icons"
                  style="margin-left: 10px; color: red"
                >
                  favorite
                </span>
                <span
                  *ngIf="gall.likesCount == 0"
                  class="material-icons"
                  style="margin-left: 10px"
                >
                  favorite_border </span
                >{{ gall.likesCount }}
              </p>
            </div>
          </div>
        </mat-card>
      </div>
    </ng-container>
  </div>
  <div style="width: 100%;" *ngIf="galleryColumns && selectedTab === 'My Posts'">
    <mat-paginator
    [length]="postDataTotal"
    [pageSize]="pageSize"
    (page)="pageChange($event.pageSize, $event.pageIndex)"
    aria-label="Select page"
    >
    </mat-paginator>
    </div>
  <div
    class="row1"
    *ngIf="TaggedgalleryColumns && selectedTab === 'Tagged Posts'"
  >
    <ng-container *ngFor="let column of TaggedgalleryColumns">
      <div class="col-lg-3 mt-3">
        <mat-card class="card-style" *ngFor="let gall of column; let i = index">
          <button mat-icon-button [matMenuTriggerFor]="menu" class="menuicon">
            <mat-icon>more_vert</mat-icon>
          </button>
          <mat-menu #menu="matMenu">
            <button mat-menu-item (click)="untagYourself(gall)">
              <span>Untag yourself</span>
            </button>
          </mat-menu>

          <img
            src="{{ gall.imageUrl }}"
            style="width: 100%; margin-top: 0px"
            (click)="GalleryPage(gall)"
          />
          <div class="row my-2" style="padding-top: 10px; padding-left: 15px">
            <div class="col-7" style="margin-top: -12px" *ngFor="let taggedListDetails of gall.taggedListDetails">
              <img src="{{taggedListDetails.taggedUserProfileUrl}}" style="width: 40px; border-radius: 40px; margin-top: 10px;"> &nbsp;<p style="margin-left: 43px; margin-top: -30px;">&#64;{{gall.taggedByUserName}}</p>  </div>
            <div class="col-5">
              <p style="display: flex; align-items: center">
                <span
                  *ngIf="gall.imageLikes >= 1"
                  class="material-icons"
                  style="margin-left: 55px; color: red"
                >
                  favorite
                </span>
                <span
                  *ngIf="gall.imageLikes < 1"
                  class="material-icons"
                  style="margin-left: 55px"
                >
                  favorite_border </span
                >{{ gall.imageLikes }}
              </p>
            </div>
          </div>
        </mat-card>
      </div>
    </ng-container>
  </div>
  <div style="width: 100%;" *ngIf="TaggedgalleryColumns && selectedTab === 'Tagged Posts'">
    <mat-paginator
    [length]="taggedDataTotal"
    [pageSize]="pageSize"
    (page)="pageChange($event.pageSize, $event.pageIndex)"
    aria-label="Select page"
    >
    </mat-paginator>
    </div>
  <div
    class="row1"
    *ngIf="RequestedgalleryColumns && selectedTab === 'Tag Requests'"
  >
    <ng-container *ngFor="let column of RequestedgalleryColumns">
      <div class="col-lg-3 mt-3">
        <mat-card class="card-style" *ngFor="let gall of column; let i = index">
          <img
            src="{{ gall.imageUrl }}"
            style="width: 100%; margin-top: 0px"
          />
          <!-- <video  style="width: 100%; margin-top: 0px" controls *ngIf="gall.mediaType == 'videoUpload'">
            <source src="{{gall.imageUrl}}" type="video/mp4">
          </video> -->
          <div class="row my-2" style="padding-top: 10px">
            <div class="col-7" style="margin-top: -10px; padding-left: 15px">
              <img src="{{gall.taggedByUserProfileUrl}}" style="width: 40px; height: 40px; border-radius: 100%; object-fit: cover;" *ngIf="gall.taggedByUserProfileUrl"/>&nbsp;
              <span>{{ gall.taggedByUserName }}</span> &nbsp;
            </div>
            <div class="col-5">
              <p style="display: flex; align-items: center">
                <span style="cursor: pointer; margin-top: 5px;" (click)="GalleryPage(gall)"><mat-icon>visibility</mat-icon></span>
                <span
                  class="material-icons-outlined"
                  style="margin-left: 30px; color: red; cursor: pointer"
                  (click)="
                    updateTagRequest(gall.imageId, gall.taggedListDetails[0].taggedId, 'denied')
                  "
                >
                  highlight_off
                </span>

                <span
                  class="material-icons-outlined"
                  style="color: green; margin-left: 10px; cursor: pointer"
                  (click)="
                    updateTagRequest(
                      gall.imageId,
                      gall.taggedListDetails[0].taggedId,
                      'approved'
                    )
                  "
                >
                  check_circle
                </span>
              </p>
            </div>
          </div>
        </mat-card>
      </div>
    </ng-container>
  </div>
  <div style="width: 100%;" *ngIf="RequestedgalleryColumns && selectedTab === 'Tag Requests'">
    <mat-paginator
    [length]="tagRequestDataTotal"
    [pageSize]="pageSize"
    (page)="pageChange($event.pageSize, $event.pageIndex)"
    aria-label="Select page"
    >
    </mat-paginator>
    </div>
</div>
<ng-template #callDeleteGalleryDialog>
  <form #userForm="ngForm">
    <p matDialogTitle style="text-align: left; font-size: 15px; margin-top:-10px"><b>Delete Confirmation !</b></p>
    <mat-divider style="margin-top:-20px"></mat-divider>
    <mat-dialog-content>
      <p style="text-align: center; padding: 30px;font: 400 20px;">
        Are you sure you want to delete this image?
      </p></mat-dialog-content
    >

    <mat-dialog-actions align="center" class="mb-2">
      <button
        mat-raised-button
        type="button"
        matDialogClose="cancel"
        style="border-radius: 10px; border: 2px; width: 100px;"
      >
        Cancel
      </button>
      <button
        class="btn-primary"
        type="button"
        mat-raised-button
        matDialogClose="delete"
        style="background-color: #525EB1; border-radius: 10px; color: #fff; width: 100px;"
      >
        Delete
      </button>
    </mat-dialog-actions>
  </form>
</ng-template>

<!-- Template to Call Image Gallery Dialog Box with Description -->
<ng-template #callImageGalleryDialog>
  <div>
    <form #userForm="ngForm" style="overflow-x: auto; overflow: hidden">
      <div class="row">
        <div class="col-md-6">
          <img
            [src]="dataFromGallery.imageUrl"
            style="
              width: 436.5px;
              height: 486px;
              display: block;
              margin: 0px;
              padding: 0px;
              object-fit: contain;
              background-color: #F1F0F5;
            "
          />
        </div>
        <div class="col-md-6">
          <div class="row">
            <div class="col-8">
              <div class="d-flex align-items-center">
                <div class="mr-3">

                </div>
                <!-- <h2>{{dataFromGallery.taggedByUserName}}</h2> -->
                <img
                  src="{{ dataFromGallery.taggedByUserProfileUrl }}"
                  style="width: 40px; border-radius: 40px; margin-top: 10px"
                />
                &nbsp;
                <p style="margin-top: 15px">
                  <b>&#64;{{ dataFromGallery.taggedByUserName }}</b>
                </p>
              </div>
            </div>
            <div class="col-3 d-flex justify-content-end align-items-center">
              <span
                class="material-icons-outlined"
                style="cursor: pointer"
                (click)="closeDialog()"
                >cancel</span
              >
            </div>
          </div>
          <hr style="width: 90%; margin: 0 auto" />
          <p class="p-3" style="height: 315px">
            {{ dataFromGallery.imageDescription }}
          </p>
          <hr style="width: 90%; margin: 0 auto" />
          <div class="d-flex align-items-center justify-content-start m-3">
            <span
              class="material-icons-outlined mr-2"
              style="cursor: pointer"
              *ngIf="dataFromGallery.imageLikes >= 1"
              >favorite_border</span
            >
            <span class="m-2" *ngIf="dataFromGallery.imageLikes >= 1">{{
              dataFromGallery.imageLikes
            }}</span>
          </div>
        </div>
      </div>
    </form>
  </div>
</ng-template>


<ng-template #callUntagDialog>
  <form #userForm="ngForm">
    <p
      matDialogTitle
      style="text-align: left; font-size: 15px; margin-top: -10px"
    >
      <b>Untag Confirmation !</b>
    </p>
    <mat-divider style="margin-top: -20px"></mat-divider>
    <mat-dialog-content>
      <p style="text-align: center; padding: 30px; font: 400 20px;">
        Are you sure you want to Untag?
      </p></mat-dialog-content
    >

    <mat-dialog-actions align="center" class="mb-2">
      <button
        mat-raised-button
        type="button"
        matDialogClose="no"
        style="border-radius: 10px; border: 2px; width: 100px"
      >
        No
      </button>
      <button
        class="btn-primary"
        type="button"
        mat-raised-button
        matDialogClose="yes"
        style="
          background-color: #525eb1;
          border-radius: 10px;
          color: #fff;
          width: 100px;
        "
      >
        YES
      </button>
    </mat-dialog-actions>
  </form>
</ng-template>
